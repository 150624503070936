import {baseURLs, http} from "../httpConfig";

//按名字搜索历史地震,params为地震名字
function getHistoryEarthquakeInfoList(params) {
    return http.get('/earthquakeInfo/getHistoryEarthquakeInfoList',{'EarthquakeName':params})
}
//地震id获取评估结果
function getEstimateResultByEarthquakeId(params) {
    return http.get('/estimate/getEstimateResultByEarthquakeId',{'earthquakeId':params})
}
//条件获取地震信息(分页)
function getEarthquakeInfoByCondition(params) {
    return http.post('/earthquakeInfo/getEarthquakeInfoByCondition',params).then(response=>{
        return response.data.list;
    })
}
//切换下一页用,初始化用
function getNextPageApi(params) {
    return http.post('/earthquakeInfo/getEarthquakeInfoByCondition',{'limit':10,'page':params,'min':4}).then(response=>{
        return response.data.list;
    })
}
//条件获取地震位置信息(地图渲染用)
function getEarthquakePositionInfoByCondition(params) {
    return http.post('/earthquakeInfo/getEarthquakePositionInfoByCondition',params).then(response=>{return response.data})
}
//查询地震带区域内信息
function getSeismicZonesEarthquakeInfo(params) {
    return http.get('/earthquakeInfo/getSeismicZonesEarthquakeInfo',params)
}
function img_predict(url) {
    return http.axios({
        url:url
    }).then(response=>{return response.data})
}
const uploadZipUrl=baseURLs.imgApi+'/upload/files/'

export{
getHistoryEarthquakeInfoList,
getEstimateResultByEarthquakeId,
getEarthquakeInfoByCondition,
getNextPageApi,
getEarthquakePositionInfoByCondition,
getSeismicZonesEarthquakeInfo,
img_predict,
uploadZipUrl
}