import {ElMessage} from "element-plus";

export const myMessage =  {
      elSuccess(message){
      ElMessage({
        type:'success',
        message:message
      })
    },
    elError(message){
      ElMessage({
        type:'error',
        message:message
      })
    }
}
// 字符串时间 2021:05:24 15:49:09 转Date
export const changeToDate=(string)=>{
const str = string;
const regex = /(\d+):(\d+):(\d+) (\d+):(\d+):(\d+)/;
let result = str.match(regex);
// console.log(result);
if (result) {
    return new Date(Number(result[1]), Number(result[2] - 1), Number(result[3]), Number(result[4]), Number(result[5]), Number(result[6]))
} else {
    console.log("无法匹配");
}
}
export const changeDateTo=(date)=> {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}:${month}:${day} ${hours}:${minutes}:${seconds}`;
}

