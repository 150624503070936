import { createRouter, createWebHistory } from 'vue-router'
import FlightPath from "@/views/FlightPath.vue";
const Cesium=()=>import('../views/Cesium')
const routes = [
  {
    path: '/',
    name: 'Cesium',
    component: Cesium
  },
  {
    path: '/flightPath',
    name: 'FlightPath',
    component: FlightPath
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
