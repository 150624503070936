export const getTimeList=(data)=> {
    const timeList=[]
    for (let i = 0; i < data.length; i++) {
        timeList.push(data[i].time)
    }
    return timeList
}

 const pathToLine = (positions) => {
    const data=positions
    // 将转折点划为上一段结束点
    const lineList=[]
    let a,b,c=[],current_line=[],index=0
    //分割为线段
    for (let i = 0; i < positions.length; i++) {
        current_line.push(positions[i])
        if (i === 0) {
            a = [positions[i].longitude, positions[i].latitude]
            continue
        }
        if (i === 1) {
            b = [positions[i].longitude, positions[i].latitude]
            continue
        }
        if (i === 2)
            c = [positions[i].longitude, positions[i].latitude]
        if (i > 2) {
            a = b
            b = c
            c = [positions[i].longitude, positions[i].latitude]
        }
        const {angle, cosine} = angle_and_cosine(a, b, c)
        // console.log(cosine)
        if (cosine > -0.8) {
            current_line.pop()
            lineList.push(current_line)
            current_line = [positions[i]]
        }
    }
    //考虑无转折点情况，将其加入
    lineList.push(current_line)
    // console.log(lineList)
    //遍历标志起点
    for (let i = 0; i < lineList.length; i++) {
        for (let j = 0; j < lineList[i].length; j++) {
            data[index]['start'] = j === 0;
            data[index]['composeName']=data[index].fileName
            // console.log(j === 0)
            index++
        }
    }
    return data
}
//将开始的第一张图片其后的图片拼接名赋值
export const labelName = (positions) => {
    let data=pathToLine(positions),newName=data[0].composeName
    for (let i = 0; i < data.length; i++) {
        if(data[i].start)
        {
            newName=data[i].composeName
        }
        data[i]['composeName']=newName
    }
    return data
}
const distance = (p1, p2) => {
    return Math.sqrt((p1[0] - p2[0]) ** 2 + (p1[1] - p2[1]) ** 2)
}

const angle_and_cosine = (p1, p2, p3) => {
    let a = distance(p1, p2)
    let b = distance(p2, p3)
    let c = distance(p1, p3)
    let cos_angle = (a ** 2 + b ** 2 - c ** 2) / (2 * a * b)
    let angle = Math.degrees(Math.acos(cos_angle))
    return {
        'angle':angle,
        'cosine':cos_angle
    }
}




